<template>
	<Modal
		title="预置团队"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div style="padding-bottom: 0">
			<h3 class="sub-title">项目类型查看设置</h3>
			<CheckboxGroup v-model="selectProConfigList">
				<Checkbox
					:label="item.id"
					v-for="item in proConfigList"
					:key="item.id"
					class="user-list-item"
				>
					<span>{{ item.name }}</span>
				</Checkbox>
			</CheckboxGroup>
		</div>
		<div>
			<h3 class="sub-title">预制人员设置</h3>
			<CheckboxGroup v-model="selectUserList">
				<Checkbox
					:label="item.id"
					v-for="item in userList"
					:key="item.id"
					class="user-list-item"
				>
					<span>{{ item.name }}</span>
				</Checkbox>
			</CheckboxGroup>
		</div>

		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/role"

const { apiGetTeamDefaultUsers, apiSetTeamDefaultUsers } = api

export default {
	name: "TeamUser",
	props: ["visible", "id"],
	data() {
		return {
			loading: false,
			userList: [], // 所有用户列表
			selectUserList: [], // 选中的用户列表的Id
			proConfigList: [], // 所有项目类型列表
			selectProConfigList: [] // 选中的项目类型列表的Id
		}
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				this.$asyncDo(async () => {
					this.loading = true
					const res = await apiGetTeamDefaultUsers({ id: this.id })
					if (res && res.data) {
						this.userList = res.data.userNodes || []
						this.selectUserList = this.userList
							.filter(item => item.checked)
							.map(item => item.id)
						this.proConfigList = res.data.projectConfigNodes || []
						this.selectProConfigList = this.proConfigList
							.filter(item => item.checked)
							.map(item => item.id)
					}
					this.loading = false
				})
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiSetTeamDefaultUsers({
					userIds: this.selectUserList,
					projectConfigIds: this.selectProConfigList,
					id: this.id
				})
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			})
		}
	}
}
</script>

<style scoped>
.user-list-item {
	margin-bottom: 15px;
	padding-right: 15px;
}
.sub-title {
	margin-bottom: 20px;
	line-height: 40px;
	font-weight: 700;
	font-size: 16px;
	border-bottom: 1px dashed #ccc;
}
</style>
