<template>
	<Modal
		:title="title"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="roleForm" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="角色名称" prop="name">
						<Input v-model="formData.name" placeholder="请输入角色名称" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="类型" prop="types">
						<Select v-model="formData.types" multiple placeholder="请选择类型">
							<Option
								v-for="list in typeList"
								:value="list.id"
								:key="list.id"
								:label="list.name"
							></Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="作用域" prop="category">
						<Select
							v-model="formData.category"
							filterable
							clearable
							placeholder="请选择作用域"
						>
							<Option :value="1">系统</Option>
							<Option :value="2">项目</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="描述" prop="descr">
						<Input
							v-model="formData.descr"
							type="textarea"
							placeholder="请输入描述"
							:rows="5"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="流程审批记录权限" prop="processApprovalAuthority">
						<Select
							v-model="formData.processApprovalAuthority"
							placeholder="请选择流程审批记录权限"
						>
							<Option :value="0">仅与角色相关内容</Option>
							<Option :value="1">全部内容</Option>
						</Select>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/role"

const { apiGet, apiAdd, apiUpdate } = api

export default {
	name: "Add",
	props: ["visible", "updateId", "typeList"],
	data() {
		return {
			loading: false,
			formData: {
				types: [],
				processApprovalAuthority: 0
			},
			title: "",
			rules: {
				name: {
					required: true,
					message: "角色名称不能为空",
					trigger: "blur"
				},
				types: [
					{
						type: "array",
						required: true,
						message: "类型不能为空",
						trigger: "change"
					}
				],
				category: {
					required: true,
					message: "作用域不能为空",
					trigger: "blur change",
					type: "number"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.roleForm.resetFields()
				this.formData = {
					category: 2,
					types: [],
					processApprovalAuthority: 0
				}
				this.title = "新增角色"
				if (this.updateId) {
					this.title = "修改角色"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.updateId })
						if (res) {
							this.formData = {
								...this.formData,
								...res.data,
								processApprovalAuthority: res.data.processApprovalAuthority || 0
							}
							if (res.data.type) {
								this.formData.types = res.data.type.split(",")
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.roleForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							res = await apiUpdate({
								...this.formData,
								type: this.formData.types.join(","),
								id: this.updateId
							})
						} else {
							res = await apiAdd({
								...this.formData,
								type: this.formData.types.join(",")
							})
						}
						if (res) {
							this.$emit("onOk")

							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style scoped></style>
