import request from "@/utils/request"
import commonApi from "../api"

const moduleName = "/auth/role"

export default {
	...commonApi(moduleName),
	apiStart: data =>
		request(`${moduleName}/${data.id}/enable`, {
			method: "put"
		}),
	apiGetPermission: data =>
		request(`${moduleName}/${data.id}/permissions`, {
			method: "get"
		}),
	apiSetPermission: data =>
		request(`${moduleName}/${data.id}/grant-permissions`, {
			method: "post",
			body: data
		}),
	// 获取列表
	apiGetPage: data =>
		request(`${moduleName}/open`, {
			params: data
		}),
	// 获取默认用户
	apiGetTeamDefaultUsers: data =>
		request(`${moduleName}/${data.id}/query-team-default-users`, {
			method: "get"
		}),
	// 分配默认用户
	apiSetTeamDefaultUsers: data =>
		request(`${moduleName}/${data.id}/grant-team-default-users`, {
			method: "post",
			body: data
		})
}
