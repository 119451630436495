<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="handleSearch" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="名称">
				<Input placeholder="请输入名称" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					v-model="searchParams.isDel"
					:transfer="true"
					placeholder="请选择状态"
					clearable
				>
					<Option :value="0">启用</Option>
					<Option :value="1">禁用</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				@click="handleAddShow"
				v-if="!(systemActionPermissions.indexOf('btn_setting_auth_add') === -1)"
				>新增角色</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		>
		</CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:typeList="typeList"
			:updateId="updateId"
		></Add>
		<Permission
			:visible="permissionVisible"
			@onCancel="permissionVisible = false"
			@onOk="handleSetPermissionOk"
			:id="permissionId"
		></Permission>
		<TeamUser
			:visible="defaultTeamVisible"
			@onCancel="defaultTeamVisible = false"
			@onOk="handleSetTeamUserOk"
			:id="defaultTeamPresetId"
		></TeamUser>
	</div>
</template>

<script>
import api from "@/api/setting/role"
import publicApi from "@/api/public"
import { mapState } from "vuex"
import Add from "./Add.vue"
import Permission from "./Permission.vue"
import TeamUser from "./TeamUser.vue"

const { apiGetPage, apiDelete } = api

const { publicGetDictionaryList } = publicApi

const columns = [
	{
		title: "角色名称",
		key: "name",
		minWidth: 300
	},
	{
		title: "状态",
		key: "isDel",
		minWidth: 100,
		render: (h, params) => h("span", {}, params.row.isDel ? "禁用" : "启用")
	},
	{
		title: "类型",
		key: "typeName",
		minWidth: 200
	}
]

export default {
	name: "index",
	components: {
		Permission,
		Add,
		TeamUser
	},
	data() {
		return {
			listData: [],
			columns,
			actionColumn: {
				title: "操作",
				key: "action",
				width: 320,
				renderButton: params => {
					const actionList = [
						{
							label: "修改",
							on: {
								click: this.handleUpdateShow
							}
						},
						{
							label: "权限分配",
							on: {
								click: this.handlePermissionShow
							}
						},
						{
							label: "预置团队",
							on: {
								click: this.handleDefaultTeamShow
							}
						},
						{
							label: params.row.isDel ? "启用" : "禁用",
							on: {
								click: this.handleSwitchState
							},
							confirm: {
								title: `您确认${params.row.isDel ? "启用" : "禁用"}这个角色吗？`
							}
						}
					]
					if (
						this.systemActionPermissions.indexOf("btn_setting_auth_add") === -1
					) {
						actionList[0] = null
						actionList[1] = null
						actionList[2] = null
					}
					return actionList.filter(item => !!item)
				}
			},
			loading: false,
			addVisible: false,
			permissionVisible: false,
			defaultTeamVisible: false,
			updateId: "",
			permissionId: "",
			defaultTeamPresetId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			typeList: [],
			searchParams: {
				name: "",
				isDel: 0
			},
			oldSearchParams: {
				name: "",
				isDel: 0
			}
		}
	},
	created() {
		this.getTypeList()
		this.initList()
		this.columns = [...columns, this.actionColumn]
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		getTypeList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await publicGetDictionaryList({ type: 1 })
				if (res) {
					this.typeList = res.data[0].leaf.filter(o => o.obj.isDel === 0)
				}
				this.loading = false
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					...this.oldSearchParams
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 搜索功能
		handleSearch() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 新增成功刷新列表
		handleSetPermissionOk() {
			this.permissionVisible = false
			this.initList()
		},
		// 预置用户成功刷新列表
		handleSetTeamUserOk() {
			this.defaultTeamVisible = false
			this.initList()
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},
		// 启用/禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 页码改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				name: "",
				isDel: 0
			}
			this.page.current = 1
			this.$nextTick(() => {
				this.handleSearch()
			})
		},
		// 权限分配
		handlePermissionShow({ row }) {
			this.permissionId = row.id
			this.permissionVisible = true
		},
		// 预置团队用户
		handleDefaultTeamShow({ row }) {
			this.defaultTeamPresetId = row.id
			this.defaultTeamVisible = true
		}
	}
}
</script>
