<template>
	<Modal
		title="权限分配"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Tree
			:data="treeData"
			ref="permissionTree"
			:render="renderContent"
			show-checkbox
			class="left-tree"
		></Tree>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/role"

const transformTreeData = data => {
	if (data && data.length) {
		return data.map(item =>
			Object.assign(item, {
				children: transformTreeData(item.leaf),
				title: item.name,
				id: item.id,
				key: item.id,
				// expand: true,
				checked: !item.leaf || !item.leaf.length ? item.obj.checked : false
			})
		)
	}
	return []
}
const { apiGetPermission, apiSetPermission } = api

export default {
	name: "Permission",
	props: ["visible", "id"],
	data() {
		return {
			loading: false,
			treeData: []
		}
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				this.$asyncDo(async () => {
					this.loading = true
					const res = await apiGetPermission({ id: this.id })
					if (res) {
						this.treeData = transformTreeData(res.data)
					}
					this.loading = false
				})
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			const permissionIds = this.$refs.permissionTree
				.getCheckedAndIndeterminateNodes()
				.map(item => item.id)
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiSetPermission({
					id: this.id,
					permissionIds
				})
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			})
		},
		renderContent(h, { data }) {
			return h(
				"span",
				{
					style: {
						display: "inline-block",
						width: "100%"
					}
				},
				[
					h("Icon", {
						props: {
							type: data.obj.type === 1 ? "ios-menu" : "logo-youtube"
						},
						style: {
							marginRight: "8px",
							color: "#2D8cF0"
						}
					}),
					h("span", data.name)
				]
			)
		}
	}
}
</script>

<style scoped></style>
